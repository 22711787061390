<template>
    <div class="sidebar-menu open">
        <nav class="desktop-menu">
            <ul class="menu">
                <li class="menu-item current-menu-item"><router-link to="/"><i class="fas fa-home"></i><span>الصفحة الرئيسية </span></router-link></li>
                <li class="menu-item"><router-link to="/Courses"><i class="fas fa-coffee"></i><span>الكورسات</span></router-link></li>
                <li class="menu-item"><router-link to="/Teachers"><i class="fa fa-user"></i><span>الاساتذة</span></router-link></li>
                <li class="menu-item pointer" v-if="showHeader"><a @click="onUnsub()" ><i class="fas fa-tag"></i><span>إلغء الاشتراك </span></a></li>
                <li class="menu-item pointer" v-else><a @click="gotoSpay()"><i class="fas fa-tag"></i><span>إشتراك </span></a></li>
            </ul>
        </nav>
    </div>
</template>

<script>
import {HTTPSPA} from '@/Api/http-Spay';

export default {
 name:'AppSideBar',
 data() {
     return {
       showHeader: false,
     }
    },
 mounted() {
     if (this.$cookie.isCookieAvailable("phone")) {
      HTTPSPA.post(`CheckSubscribtion.php?msisdn=${this.$cookie.getCookie("phone")}&service_code=5325`).then((res) => {
                if (res.data.MINTUES != null && res.data.MINTUES > 0) {
                    this.showHeader =  true;
                }else{
                    // this.$cookie.removeCookie("phone");
                    this.showHeader = false;
                }
            })
        
           
        } else {
            
           this.showHeader = false;
        }
  },
  methods: {
      gotoSpay() {
        window.open("http://courseatk.net/MTN_LandingPage_APIs/subscribe.php?portalId=1");
    },
    onUnsub() {
      if(this.$cookie.isCookieAvailable("phone")){
      window.open("http://courseatk.net/MTN_LandingPage_APIs/APIs/UnSub.php?misidn="+this.$cookie.getCookie("phone")+"&shortCode=5325&key=C1");
        }
    },
  }
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>