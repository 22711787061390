<template>
    <footer class="site-footer">
            <div class="container" style="padding-right: 0;padding-left: 0;">
                
                
            </div>
            <div class="copyright-bar">
                <svg style="fill: #FC0;margin-bottom: -10px;" viewBox="0 0 500 34" preserveAspectRatio="xMidYMin meet" class="mtn-curve__concave mtn-curve__curve-mobile">
            <path d="M0,0 C76.4327922,21.6962525 157.300642,32.5443787 242.60355,32.5443787 C327.906458,32.5443787 413.705275,21.6962525 500,0 L500,34 L0,34 L0,0 Z" class="mtn-svg-fill--yellow"></path>
        </svg>
        <div class="row" style="margin-right:0;margin-left:0;background:#FC0;justify-content: center;">

<div class="col-6 col-sm-6 col-lg-6 col-xl-3" style="justify-content: center;display: flex;">
    <a>
        <img style="max-width:40px;border: 1px solid #fff;" class="img-fluid" src="../../assets/images/mtn.png" title="appstore" alt="appstore">
    </a>
</div>
<div class="col-6 col-sm-6 col-lg-6 col-xl-3" style="justify-content: center;display: flex;">
    <a >
        <img style="max-width: 150px;margin-top: 5px;" class="img-fluid" src="../../assets/images/CR_logo.png" title="appstore" alt="appstore">
    </a>
</div>
</div>
                <div class="col-sm-12 text-center" style="background: #fc0;">

                    <p>Copyright © 2022 Byte All Rights Reserved. </p>
                </div>
            </div>
        </footer>
<div id="backtotop" style="display: block;"><i class="fal fa-lg fa-arrow-up"></i></div>
</template>

<script>
export default {
   name: 'AppFooter'
}
</script>

<style>

</style>